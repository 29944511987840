import React, {Component} from 'react'
// import { css } from 'react-emotion';
import ReactDOM from 'react-dom'
// First way to import
//  import { PacmanLoader } from 'react-spinners';
// import { className } from 'postcss-selector-parser';
// Another way to import
// import PacmanLoader from 'react-spinners/ClipLoader';
import './pacman.css'

// const override = css`
//     display: block;
//     margin: 0 auto;
//     border-color: red;
// `;

const Spinner = props => (
  <div id="loading">
    <div className="sweet-loading">
      <div className="lds-css ng-scope">
        <div className="lds-spinner" style={{width:'100%',height:'100%'}}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
)

class SpinnerContent extends Component {
  render() {
    return this.props.loading
      ? ReactDOM.createPortal(
          <Spinner loading={this.props.loading} />,
          document.getElementById('modal-root'),
        )
      : null
  }
}

export default SpinnerContent
