
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'

import {Router, Route, Switch} from 'react-router-dom'
import UserLayout from 'layouts/User.jsx'
import AppProviders from './context'
import {transitions, positions, Provider as AlertProvider} from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import {createBrowserHistory} from 'history'
import * as serviceWorker from './serviceWorker'

// require('es6-promise').polyfill()

// import 'promise-polyfill/src/polyfill';
// optional cofiguration
const options = {
  // you can also just use 'bottom center'
  position: positions.TOP_RIGHT,
  timeout: 5000,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.SCALE,
  containerStyle: {
    zIndex: 9999,
  },
}
const hist = createBrowserHistory()

ReactDOM.render(
  <AppProviders>
    <AlertProvider template={AlertTemplate} {...options}>
    <Router history={hist}>
        <Switch>
          <Route path="/user" component={UserLayout} />
          <Route path="/" component={App} />
        </Switch>
      </Router>
    </AlertProvider>
  </AppProviders>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()





// import 'react-app-polyfill/ie11'
// import 'react-app-polyfill/stable'

// import React from 'react'
// import ReactDOM from 'react-dom'
// import {Router, Route, Switch} from 'react-router-dom'
// import UserLayout from 'layouts/User.jsx'
// import AppProviders from './context'
// import {transitions, positions, Provider as AlertProvider} from 'react-alert'
// import AlertTemplate from 'react-alert-template-basic'
// import {createBrowserHistory} from 'history'
// import * as serviceWorker from './serviceWorker'
// import 'assets/scss/material-dashboard-pro-react.scss'
// const App = React.lazy(() => import('./App'))
// // require('es6-promise').polyfill()

// // import 'promise-polyfill/src/polyfill';
// // optional cofiguration
// const options = {
//   // you can also just use 'bottom center'
//   position: positions.TOP_RIGHT,
//   timeout: 5000,
//   offset: '30px',
//   // you can also just use 'scale'
//   transition: transitions.SCALE,
//   containerStyle: {
//     zIndex: 9999,
//   },
// }
// const hist = createBrowserHistory()

// function AppRender() {
//   return (
//     <React.Suspense>
//       <App />
//     </React.Suspense>
//   )
// }

// ReactDOM.render(
//   <AppProviders>
//     <AlertProvider template={AlertTemplate} {...options}>
//       <Router history={hist}>
//         <Switch>
//           <Route path="/user" component={UserLayout} />
//           <Route path="/" component={AppRender} />
//         </Switch>
//       </Router>
//     </AlertProvider>
//   </AppProviders>,
//   document.getElementById('root'),
// )

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()
