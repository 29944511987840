import React from 'react'
import {useAsync} from 'react-async'
import {bootstrapAppData} from '../utils/bootstrap'
import * as authClient from '../utils/auth-client'
import {FullPageSpinner} from '../components/lib'

// async function loadDataUser(user = null) {
//   debugger
//   return {
//     user,
//   }
// }
const AuthContext = React.createContext()
function AuthProvider(props) {
  const [firstAttempFinished, setFirstAttempFinished] = React.useState(false)
  const {
    data = { user: null },
    //data = {email: null, name: null, token: null},
    error,
    isRejected,
    isPending,
    isSettled,
    reload,
    setData,
  } = useAsync({
    promiseFn: bootstrapAppData,
  })

  React.useLayoutEffect(() => {
    if (isSettled) {
      setFirstAttempFinished(true)
    }
  }, [isSettled])

  if (!firstAttempFinished) {
    if (isPending) {
      return <FullPageSpinner />
    }
    if (isRejected) {
      return (
        <div /* css={{ color: "red" }} */>
          <p>Uh oh... There's a problem. Try refreshing the app.</p>
          <pre>{error.message}</pre>
        </div>
      )
    }
  }

  // const login = () =>
  //   authClient.login().then(() => {
  //     reload()
  //   })
  const login = form =>
    authClient.login(form).then(user => {
      
      setData({user})
      return user
    })
  const register = form => authClient.register(form).then(reload)
  const logout = () => authClient.logout().then(reload)

  return (
    <AuthContext.Provider value={{data, login, logout, register}} {...props} />
  )
}

function useAuth() {
  const context = React.useContext(AuthContext)
  if (context === undefined)
    throw new Error(`useAuth must be used within a AuthProvider`)

  return context
}

const AuthConsumer = AuthContext.Consumer

export {AuthProvider, useAuth, AuthConsumer}
