import React from 'react'
import {useUser} from './context/user-context'
import {FullPageSpinner} from './components/lib'
import 'assets/scss/material-dashboard-pro-react.scss'
const AuthenticatedApp = React.lazy(() => import('./authenticated-app'))
// const UnauthenticatedApp2 = React.lazy(() => import('./unauthenticated-app2'))
const UnauthenticatedApp = React.lazy(() => import('./unauthenticated-app'))
function App(props) {
  const user = useUser()
  return (
    <React.Suspense fallback={<FullPageSpinner />}>
      {user ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    </React.Suspense>
  )
}

export default App