import React from 'react'
import PropTypes from 'prop-types'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// import FormControlLabel from '@material-ui/core/FormControlLabel'
// import FormLabel from '@material-ui/core/FormLabel'
import Checkbox from '@material-ui/core/Checkbox'
// import InputAdornment from '@material-ui/core/InputAdornment'
import ListItemText from '@material-ui/core/ListItemText'
import SweetAlert from 'react-bootstrap-sweetalert'

import Spinner from 'components/Spinner/Spinner'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'

// material ui icons
import MailOutline from '@material-ui/icons/MailOutline'
// import Contacts from '@material-ui/icons/Contacts'
// import Check from '@material-ui/icons/Check'
// import Close from '@material-ui/icons/Close'

// core components
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import CustomInput from 'components/CustomInput/CustomInput.jsx'
import Button from 'components/CustomButtons/Button.jsx'
import Card from 'components/Card/Card.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'
// import CardText from 'components/Card/CardText.jsx'
import CardIcon from 'components/Card/CardIcon.jsx'
import CardBody from 'components/Card/CardBody.jsx'
// import CardFooter from 'components/Card/CardFooter.jsx'
import axios from 'axios'
// style for this view
// import validationFormsStyle from 'assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx'
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx'

import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx'

const styles = {
  ...extendedFormsStyle,
  ...sweetAlertStyle,
}

// 1- ¿Tipo de vivienda? Unifamiliar, Plurifamiliar, otros (
//   esto debe ser a elegir entre uno de ellos)  Como en vuestra página.
// 2- Código postal
// 3- Población
// 4- Dirección
// 5- Número
// 6- ¿Dispone de contrato de suministro eléctrico? Si, No (igual que arriba)
// 7- ¿Cuándo consume más energía? Mañana, tarde, noche (esto debe poder
//   seleccionarse como en vuestra página)
// 8- ¿Cuánta energía consume al mes? Debe ser un menú desplegable que va
// desde 100 KWh, 200 KWh...….1500KWh
// 9- Comentarios adicionales. Esto sería para que el cliente detallase a
// lgún aspecto en concreto que no se halla recogido en el formulario.

const optionsHousesTypes = [
  {id: '1', title: 'Unifamiliar'},
  {id: '2', title: 'Plurifamiliar'},
  {id: '3', title: 'Otros'},
]

const optionsEnergyInfo = [
  {id: 1, title: 'Mañana'},
  {id: 2, title: 'Tarde'},
  {id: 3, title: 'Noche'},
  {id: 4, title: 'Indistintamente'},
]
const optionsConsumeTypes = [
  {id: '1', title: '100KWh a 200KWh'},
  {id: '2', title: '200KWh a 300KWh'},
  {id: '3', title: '300KWh a 400KWh'},
  {id: '4', title: '400KWh a 500KWh'},
  {id: '5', title: '500KWh a 600KWh'},
  {id: '6', title: '600KWh a 700KWh'},
  {id: '7', title: '700KWh a 800KWh'},
  {id: '8', title: '800KWh a 900KWh'},
  {id: '9', title: '900KWh a 1000KWh'},
  {id: '10', title: '1000KWh a 1100KWh'},
  {id: '11', title: '1100KWh a 1200KWh'},
  {id: '12', title: '1200KWh a 1300KWh'},
  {id: '13', title: '1300KWh a 1400KWh'},
  {id: '14', title: '1400KWh a 1500KWh'},
]

const initialState = {
  house_type_id: '',

  whatsapp_contact: 1,
  postal_code: '',
  poblation: '',
  address: '',
  phone_number: '',

  electric_contract: '',
  power_contracted: '',
  energyInfo: [],
  consume_type_id: '',
  comments: '',

  house_type_idState: '',
  postal_codeState: '',
  poblationState: '',
  addressState: '',
  phone_numberState: '',
  electric_contractState: '',
  power_contractedState: '',
  energyInfoState: '',
  consume_type_idState: '',
  commentsState: '',
}

function formatData(data) {
  return {
    token: data.service.token,
    house_type_id: data['house_type_id'],
    whatsapp_contact: '1',
    electric_contract: data['electric_contract'],
    power_contracted:
      data['electric_contract'] === '1' ? data['power_contracted'] : undefined,
    consume_type_id: data['consume_type_id'],
    comments: data['comments'],

    aditional_info: {
      postal_code: data['postal_code'],
      poblation: data['poblation'],
      address: data['address'],
      phone_number: data['phone_number'],
    },

    energyInfo: data['energyInfo'],
  }
}
class CreateAdvancedForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      alert: null,
      loading: true,
      // register form
      ...initialState,
    }
  }

  componentDidMount() {
    const headers = {'content-type': 'application/json'}
    axios(
      `${process.env.REACT_APP_API_URL}/services/${this.props.match.params.serviceId}`,
      {
        method: 'GET',

        headers: {
          ...headers,
        },
      },
    )
      .then(response => {
        this.setState({service: (response.data || {}).data})
      })
      .finally(() => {
        this.setState({loading: false})
      })
  }

  change(event, stateName, type, stateNameEqualTo, maxValue) {
    switch (type) {
      case 'checkbox':
        this.setState({[stateName]: event.target.checked})
        break
      default:
        this.setState({
          [stateName + 'State']: 'success',
        })
        break
    }
    this.setState({
      [stateName]: event.target.value,
    })
  }
  successAlert() {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{display: 'block', marginTop: '-200px'}}
          title="Good job!"
          confirmBtnText="Ir a la pagina principal"
          onConfirm={() => (window.location = 'https://exosolar.es')}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + ' ' + this.props.classes.success
          }
        >
          Gracias por completar la información. En la mayor brevedad posible uno
          de nuestros agentes se pondrá en contacto contigo. Que tengas un
          excelente día.
        </SweetAlert>
      ),
      ...initialState,
    })
  }

  hideAlert() {
    this.setState({
      alert: null,
    })
  }

  errorAlert({error}) {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{display: 'block', marginTop: '-200px'}}
          title="Error"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + ' ' + this.props.classes.success
          }
        >
          {error
            ? error
            : 'Ocurrio un error al intentar guardar la información'}
        </SweetAlert>
      ),
    })
  }

  saveClick = () => {
    // if (this.state.loginEmailState === '') {
    //   this.setState({loginEmailState: 'error'})
    // }
    // if (this.state.loginPasswordState === '') {
    //   this.setState({loginPasswordState: 'error'})
    // }
    this.sendData()
  }

  sendData() {
    this.setState({loading: true})
    const formData = formatData(this.state)
    const headers = {'content-type': 'application/json'}
    axios(`${process.env.REACT_APP_API_URL}/services/edit`, {
      method: 'POST',

      headers: {
        ...headers,
      },
      data: formData,
    })
      .then(response => {
        this.successAlert()
      })
      .catch(error => {
        this.errorAlert(error.response.data)
      })
      .finally(() => {
        this.setState({loading: false})
      })
  }

  render() {
    const {classes} = this.props
    return (
      <div
        style={{
          marginLeft: 'auto',
          marginRight: 'auto',
          paddingLeft: '15px',
          paddingRight: '15px',
        }}
      >
        {this.state.alert}
        <Spinner loading={this.state.loading} />
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={11}>
            <Card>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <MailOutline />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>Formulario Avanzado</h4>
              </CardHeader>
              <CardBody>
                <form>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classes.selectLabel}
                        >
                          ¿Tipo de vivienda? *
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={this.state.house_type_id}
                          onChange={event =>
                            this.change(event, 'house_type_id')
                          }
                          inputProps={{
                            name: 'house_type_id',
                            id: 'house_type_id',
                          }}
                        >
                          <MenuItem value="" disabled>
                            Seleccione una opción
                          </MenuItem>
                          {optionsHousesTypes.map(item => (
                            <MenuItem value={item.id} key={item.id}>
                              {item.title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="Código postal *"
                        id="postal_code"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: event => this.change(event, 'postal_code'),
                          value: this.state.postal_code || '',
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="Población *"
                        id="poblation"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: event => this.change(event, 'poblation'),
                          value: this.state.poblation || '',
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="Dirección *"
                        id="address"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: event => this.change(event, 'address'),
                          value: this.state.address || '',
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        success={this.state.registerEmailState === 'success'}
                        error={this.state.registerEmailState === 'error'}
                        labelText="Número *"
                        id="phone_number"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: event => this.change(event, 'phone_number'),
                          value: this.state.phone_number || '',
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={4}>
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel className={classes.selectLabel}>
                          ¿Dispone de contrato de suministro eléctrico? *
                        </InputLabel>
                        <Select
                          classes={{
                            select: classes.select,
                          }}
                          value={this.state.electric_contract}
                          onChange={event =>
                            this.change(event, 'electric_contract')
                          }
                          inputProps={{
                            name: 'electric_contract',
                            id: 'electric_contract',
                          }}
                        >
                          <MenuItem value="" disabled>
                            Seleccione una opción
                          </MenuItem>
                          <MenuItem value="1">Si</MenuItem>
                          <MenuItem value="0">No</MenuItem>
                        </Select>
                      </FormControl>
                    </GridItem>
                    {parseInt(this.state.electric_contract, 10) === 1 && (
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          success={this.state.registerEmailState === 'success'}
                          error={this.state.registerEmailState === 'error'}
                          labelText="Potencia contratada"
                          id="power_contracted"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            onChange: event =>
                              this.change(event, 'power_contracted'),
                            value: this.state.power_contracted || '',
                          }}
                        />
                      </GridItem>
                    )}
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel className={classes.selectLabel}>
                          ¿Cuándo consume más energía? *
                        </InputLabel>
                        <Select
                          multiple
                          classes={{
                            select: classes.select,
                          }}
                          renderValue={selected => {
                            return selected.reduce(
                              (prevValue, currentValue) => {
                                const title = optionsEnergyInfo.find(
                                  item => item.id === currentValue,
                                ).title

                                return prevValue === ''
                                  ? title
                                  : prevValue + ', ' + title
                              },
                              '',
                            )
                          }}
                          value={this.state.energyInfo}
                          onChange={event => this.change(event, 'energyInfo')}
                          inputProps={{
                            name: 'energyInfo',
                            id: 'energyInfo',
                          }}
                        >
                          <MenuItem value="" disabled>
                            Seleccione una opción
                          </MenuItem>

                          {optionsEnergyInfo.map(item => (
                            <MenuItem value={item.id} key={item.id}>
                              <Checkbox
                                checked={
                                  this.state.energyInfo.indexOf(item.id) > -1
                                }
                              />
                              <ListItemText primary={item.title} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={4}>
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel className={classes.selectLabel}>
                          ¿Cuánta energía consume al mes? *
                        </InputLabel>
                        <Select
                          classes={{
                            select: classes.select,
                          }}
                          value={this.state.consume_type_id}
                          onChange={event =>
                            this.change(event, 'consume_type_id')
                          }
                          inputProps={{
                            name: 'consume_type_id',
                            id: 'consume_type_id',
                          }}
                        >
                          <MenuItem value="" disabled>
                            Seleccione una opción
                          </MenuItem>
                          {optionsConsumeTypes.map(item => (
                            <MenuItem value={item.id} key={item.id}>
                              {item.title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText="Comentarios adicionales"
                        id="comments"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: event => this.change(event, 'comments'),
                          multiline: true,
                          rows: 3,
                          rowsMax: 10,
                          value: this.state.comments || '',
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <div className={classes.formCategory}>
                    <small>*</small> Campos Requeridos
                  </div>

                  <Button color="info" onClick={this.saveClick}>
                    Enviar
                  </Button>
                </form>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    )
  }
}

CreateAdvancedForm.propTypes = {
  classes: PropTypes.object,
}

export default withStyles(styles)(CreateAdvancedForm)
