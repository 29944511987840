import React from 'react'
import PropTypes from 'prop-types'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'

// material ui icons
import MailOutline from '@material-ui/icons/MailOutline'

// core components
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import CustomInput from 'components/CustomInput/CustomInput.jsx'
import Button from 'components/CustomButtons/Button.jsx'
import Card from 'components/Card/Card.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'
// import CardText from 'components/Card/CardText.jsx'
import CardIcon from 'components/Card/CardIcon.jsx'
import CardBody from 'components/Card/CardBody.jsx'
// import CardFooter from 'components/Card/CardFooter.jsx'
import axios from 'axios'
import SweetAlert from 'react-bootstrap-sweetalert'
import Spinner from 'components/Spinner/Spinner'
// style for this view
import validationFormsStyle from 'assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx'
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx'

const styles = {
  ...validationFormsStyle,
  ...sweetAlertStyle,
}
const initialState = {
  email: '',
  emailState: '',
  name: '',
  nameState: '',
  lastName: '',
  lastNameState: '',
}

class ValidationForms extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      // register form
      ...initialState,
    }
  }

  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (emailRex.test(value)) {
      return true
    }
    return false
  }

  change(event, stateName, type, stateNameEqualTo, maxValue) {
    switch (type) {
      case 'email':
        if (this.verifyEmail(event.target.value)) {
          this.setState({[stateName + 'State']: 'success'})
        } else {
          this.setState({[stateName + 'State']: 'error'})
        }
        break
      default:
        this.setState({
          [stateName + 'State']: 'success',
        })
        break
    }
    this.setState({
      [stateName]: event.target.value,
    })
  }
  saveClick = event => {
    event && event.preventDefault()
    let error = false
    if (this.state.email === '') {
      error = true
      this.setState({emailState: 'error'})
    }
    if (this.state.name === '') {
      error = true
      this.setState({nameState: 'error'})
    }
    if (this.state.lastName === '') {
      error = true
      this.setState({lastNameState: 'error'})
    }
    if (
      this.state.emailState !== 'error' &&
      this.state.nameState !== 'error' &&
      this.state.lastNameState !== 'error' &&
      !error
    ) {
      this.sendData()
    }
  }

  sendData() {
    this.setState({loading: true})
    const formData = {
      first_name: this.state.name,
      last_name: this.state.lastName,
      email: this.state.email,
    }
    const headers = {'content-type': 'application/json'}
    axios(`${process.env.REACT_APP_API_URL}/persons`, {
      method: 'POST',

      headers: {
        ...headers,
      },
      data: formData,
    })
      .then(response => {
        this.successAlert()
      })
      .catch(error => {
        this.errorAlert(error.response.data)
      })
      .finally(() => {
        this.setState({loading: false})
      })
      .finally(() => {
        this.setState({loading: false})
      })
  }

  successAlert() {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{display: 'block', marginTop: '-200px'}}
          title="Good job!"
          confirmBtnText="Ir a la pagina principal"
          onConfirm={() => (window.top.location.href = 'https://exosolar.es')}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + ' ' + this.props.classes.success
          }
        >
          Gracias por registrarte. En breves recibirás un email con más
          información.
        </SweetAlert>
      ),
      ...initialState,
    })
  }

  hideAlert() {
    this.setState({
      alert: null,
    })
  }

  errorAlert({error}) {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{display: 'block', marginTop: '-200px'}}
          title="Error"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + ' ' + this.props.classes.success
          }
        >
          {error
            ? error
            : 'Ocurrio un error al intentar guardar la información'}
        </SweetAlert>
      ),
    })
  }

  render() {
    const {classes} = this.props
    return (
      <div
        style={{
          marginLeft: 'auto',
          marginRight: 'auto',
          paddingLeft: '15px',
          paddingRight: '15px',
        }}
      >
        {this.state.alert}
        <Spinner loading={this.state.loading} />
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <MailOutline />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>Formulario Simple</h4>
              </CardHeader>
              <CardBody>
                <form action={this.saveClick}>
                  <CustomInput
                    success={this.state.emailState === 'success'}
                    error={this.state.emailState === 'error'}
                    labelText="Correo Electronico *"
                    id="email"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: this.state.email || '',
                      onChange: event => this.change(event, 'email', 'email'),
                      type: 'email',
                    }}
                  />
                  <CustomInput
                    success={this.state.nameState === 'success'}
                    error={this.state.nameState === 'error'}
                    labelText="Nombre"
                    id="name"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: this.state.name || '',
                      onChange: event => this.change(event, 'name'),
                    }}
                  />
                  <CustomInput
                    success={this.state.lastNameState === 'success'}
                    error={this.state.lastNameState === 'error'}
                    labelText="Apellido"
                    id="lastName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: this.state.lastName || '',
                      onChange: event => this.change(event, 'lastName'),

                      autoComplete: 'off',
                    }}
                  />
                  <div className={classes.formCategory}>
                    <small>*</small> Campos Requeridos
                  </div>

                  <Button color="info" onClick={this.saveClick}>
                    Enviar
                  </Button>
                </form>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    )
  }
}

ValidationForms.propTypes = {
  classes: PropTypes.object,
}

export default withStyles(styles)(ValidationForms)
