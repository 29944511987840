import client from './api-client'

const localStorageKey = '__token__'

function handleUserResponse({email, name, token}) {
  if (!token) {
    alert('error, verifique las credenciales')
    return
  }
  
  window.localStorage.setItem(localStorageKey, token)
  return {email, name, token} // return user
}
// function handleUserResponse(response) {
//   if (response.error) {
//     alert('error, verifique las credenciales')
//     return
//   }
//   debugger
//   window.localStorage.setItem(localStorageKey, response.token)
//   return response
// }

function getUser() {
  const token = getToken()
  if (!token) {
    return Promise.resolve(null)
  }
  return client('auth/user/' + token)
    .then(response => response.data)
    .catch(error => {
      logout()
      return Promise.resolve({user: null})
    })
}

function login({email, password}) {
  return client('login', {method: 'POST', data: {email, password}}).then(
    response => handleUserResponse(response.data),
  )
}

function register({username, password}) {
  return client('register', {body: {username, password}}).then(
    handleUserResponse,
  )
}

function logout() {
  
  window.localStorage.removeItem(localStorageKey)
  return Promise.resolve()
}

function getToken() {
  return window.localStorage.getItem(localStorageKey)
}

export {login, register, logout, getToken, getUser}
